body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*  START FONT IMPORTS */

@font-face {
  font-family: 'Montserrat-Black';
  src: url(./assets/fonts/Montserrat/Montserrat-Black.ttf);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(./assets/fonts/Montserrat/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url(./assets/fonts/Montserrat/Montserrat-Medium.ttf);
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url(./assets/fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Roboto-Black';
  src: url(./assets/fonts/Roboto/Roboto-Black.ttf);
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url(./assets/fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url(./assets/fonts/Roboto/Roboto-Thin.ttf);
}

/*  END FONT IMPORTS */